import React from "react"; // You only need to import React here

import { FiX } from "react-icons/fi";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
// @ts-ignore

import { HeaderLink } from "./HeaderLink";
import "./Header.css";
import { isHomeSite } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import logoImg from "../../img/AIDX_Logo.png";


type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">

       <Link className="App-header-link-main" to="/">

            {/* @ts-ignore */}
  <img src={logoImg} alt="ADX Logo" />
 </Link>

          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      {small && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/home"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Home</Trans>
          </HeaderLink>
        </div>
      )}
      <div className="App-header-link-container">
        <HeaderLink
          to="/spot_trading"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Spot Trading</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to="/perpetual_trading"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Perpetual Trading</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to="/analytics"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Analytics</Trans>
        </HeaderLink>
      </div>
      <br/>
      {small && (
        <div className="App-header-link-container">
          <a target="_blank" href='https://finch.aidx.exchange/' rel="noopener noreferrer">
            Finch AI Trading Helper
          </a>
        </div>    
      )}  
      {small && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/earn"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Earn</Trans>
          </HeaderLink>
        </div>
      )}
      
      {false && small && !isHomeSite() && (
        <div className="App-header-link-container">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={openSettings}>
            <Trans>Settings</Trans>
          </a>
        </div>
      )}
    </div>
  );
}
